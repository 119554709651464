import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "moment/locale/de-at";

import "./App.scss";
import Home from "./pages/Home";
import CMS from "./CMS/CMS";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";

class App extends Component {
  render() {
    return (
      <Router>
        <Route
          path="/"
          exact
          component={() => <Home data={{ ...this.state }} />}
        />
      <Route
          path="/datenschutz"
          exact
          component={() => <Datenschutz data={{ ...this.state }} />}
      />
          <Route
              path="/privacy"
              exact
              component={() => <Datenschutz data={{ ...this.state }} />}
          />
          <Route
              path="/impressum"
              exact
              component={() => <Impressum data={{ ...this.state }} />}
          />
        <Route
          path="/admin"
          exact
          component={() => <CMS data={{ ...this.state }} />}
        />
      </Router>
    );
  }
}

export default App;
