import React, { Component } from "react";
import Image from "../components/Image";
import GLightbox from 'glightbox';

class Home extends Component {
  constructor(props){
    super(props);
    this.lightboxDescription = GLightbox({
      selector: 'glightbox'
    });
  }

  render() {
    return (
      <section className={"hero center text-center"}>
          <h1>
                <Image src={`logo.png`}  alt={`Logo von Haparo`} width={128} className={`logo`}/>
          </h1>
        <p>
          HaPaRo GmbH<br/>
          Franziskanerstr. 16<br/>
          81669 München
        </p>

        <p>
          <a href="tel:+498941155877">+49 (89) 41 15 58 77</a><br/>
          <a href="mailto:info@haparo.com">info@haparo.com</a>
        </p>

        <p>
          <a href="https://simplapp.io/">SiMPL App</a><br/>
          <a href="/impressum">Impressum</a><br/>
          <a href="/datenschutz">Datenschutz</a>
        </p>
      </section>
    );
  }
}

export default Home;
