import React, { Component } from "react";
import { fromJS } from "immutable";
import axios from "axios";
import Select from "react-select";
import TextArea from "./TextArea";
import ImageUpload from "./ImageUpload";
import Login from "./Login";
import Image from "../components/Image";

const cmsUrl =
  process.env.NODE_ENV === "development"
    ? "http://optikrudolf.at.test/public/cms"
    : "/cms";

class CMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      username: "",
      password: "",
      loggedIn: false
    };

    this.saveTimer = null;
  }

  componentDidMount() {
    this.setState({ data: fromJS(this.props.data) });
  }

  login = event => {
    event.preventDefault();

    axios
      .post(`${cmsUrl}/login`, {
        username: this.state.username,
        password: this.state.password
      })
      .then(response => {
        // handle success
        // this.setState({...response.data, loading: false});
        this.setState({ loggedIn: response.data.status });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  uploadFile = (data, name) => {
    const formData = new FormData();
    formData.append("username", this.state.username);
    formData.append("password", this.state.password);
    formData.append("dataname", name);
    formData.append("file", data[0]);

    axios
      .post(`${cmsUrl}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        // handle success
        if (response.data.status) {
          if (name === "trends.images") {
            this.updateData(
              this.state.data.updateIn(["trends", "images"], arr =>
                arr.push(fromJS({ url: response.data.file, title: '' }))
              )
            );
          } else {
            this.updateData(
              this.state.data.setIn(name.split("."), response.data.file)
            );
          }
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  updateData = data => {
    this.setState({ data });

    if (this.saveTimer !== null) {
      clearTimeout(this.saveTimer);
      this.saveTimer = null;
    }

    this.saveTimer = setTimeout(() => {
      this.saveData();
    }, 2000);
  };

  saveData = () => {
    console.log(this.state.data.toJS());

    axios
      .post(`${cmsUrl}/update`, {
        data: this.state.data.toJS(),
        username: this.state.username,
        password: this.state.password
      })
      .then(response => {
        // handle success
        // this.setState({...response.data, loading: false});
        console.log(response.data);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  renderTrends = () => {
    const trends = [];
    const newTrends = [];

    if (this.state.data.getIn(["trends", "images"])) {
      this.state.data.getIn(["trends", "images"]).forEach((image, index) => {
        if (typeof image === "string") {
          newTrends.push({
            url: image,
            title: ""
          });
        } else {
          trends.push(
            <div
              key={`trend_image_${image.get("url")}`}
              className={`${this.state.data.getIn([
                "trends",
                "imagesPerRowMobile"
              ])} ${this.state.data.getIn(["trends", "imagesPerRowDesktop"])}`}
            >
              <Image src={image.get("url")} width={200} height={200} />
              <input
                type="text"
                value={image.get("title")}
                onChange={event => {
                  this.updateData(
                    this.state.data.updateIn(
                      ["trends", "images", index],
                      element => element.set("title", event.target.value)
                    )
                  );
                }}
              />
              <button
                onClick={() => {
                  this.updateData(
                    this.state.data.updateIn(["trends", "images"], arr =>
                      arr.delete(index)
                    )
                  );
                }}
              >
                löschen
              </button>
            </div>
          );
        }
      });
    }

    if (newTrends.length) {
      this.updateData(
        this.state.data.setIn(["trends", "images"], fromJS(newTrends))
      );
      return <div>Refreshing!!</div>;
    }

    return trends;
  };

  render() {
    if (this.state.data === null) {
      return <h3>Loading content.</h3>;
    }

    if (this.state.loggedIn === false) {
      return (
        <Login
          setState={data => this.setState(data)}
          login={this.login}
          username={this.state.username}
          password={this.state.password}
        />
      );
    }

    const desktopSizes = [
      {
        value: "col-sm-12",
        label: "1 pro Reihe"
      },
      {
        value: "col-sm-6",
        label: "2 pro Reihe"
      },
      {
        value: "col-sm-4",
        label: "3 pro Reihe"
      },
      {
        value: "col-sm-3",
        label: "4 pro Reihe"
      },
      {
        value: "col-sm-2",
        label: "6 pro Reihe"
      },
      {
        value: "col-sm-1",
        label: "12 pro Reihe"
      }
    ];

    const mobileSizes = [
      {
        value: "col-xs-12",
        label: "1 pro Reihe"
      },
      {
        value: "col-xs-6",
        label: "2 pro Reihe"
      },
      {
        value: "col-xs-4",
        label: "3 pro Reihe"
      },
      {
        value: "col-xs-3",
        label: "4 pro Reihe"
      },
      {
        value: "col-xs-2",
        label: "6 pro Reihe"
      },
      {
        value: "col-xs-1",
        label: "12 pro Reihe"
      }
    ];

    const currentDesktop = desktopSizes.filter(
      obj =>
        obj.value === this.state.data.getIn(["trends", "imagesPerRowDesktop"])
    );
    const currentMobile = mobileSizes.filter(
      obj =>
        obj.value === this.state.data.getIn(["trends", "imagesPerRowMobile"])
    );

    return (
      <div className="cms">
        <div className="wrapper">
          <section>
            <h1>Hero (erstes Bild)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <TextArea
                  name="hero1.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="hero1.button"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Button"
                  rows={1}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <ImageUpload
                  name="hero1.imageDesktop"
                  title="Bild Desktop"
                  uploadFile={this.uploadFile}
                  state={this.state.data}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <ImageUpload
                  name="hero1.imageMobile"
                  title="Bild Mobile"
                  uploadFile={this.uploadFile}
                  state={this.state.data}
                />
              </div>
            </div>
          </section>
          <section>
            <h1>Content (Seit über 25...)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <TextArea
                  name="content1.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="content1.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <TextArea
                  name="content1.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content1.imageDesktop"
                      title="Bild Desktop"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content1.imageMobile"
                      title="Bild Mobile"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h1>Trends</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <TextArea
                  name="trends.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <label>Bilder am Desktop</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={currentDesktop}
                  isClearable={false}
                  isSearchable
                  name="trends.imagesPerRowDesktop"
                  options={desktopSizes}
                  onChange={value => {
                    this.updateData(
                      this.state.data.setIn(
                        ["trends", "imagesPerRowDesktop"],
                        value.value
                      )
                    );
                  }}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <label>Bilder am Smartphone</label>
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={currentMobile}
                  isClearable={false}
                  isSearchable
                  name="trends.imagesPerRowMobile"
                  options={desktopSizes}
                  onChange={value => {
                    this.updateData(
                      this.state.data.setIn(
                        ["trends", "imagesPerRowMobile"],
                        value.value
                      )
                    );
                  }}
                />
              </div>
              <div className="col-xs-12">
                <div className="row">{this.renderTrends()}</div>
              </div>
              <div className="col-xs-12 col-sm-12">
                <ImageUpload
                  name="trends.images"
                  title="Neues Bild hochladen"
                  uploadFile={this.uploadFile}
                  state={this.state.data}
                />
              </div>
            </div>
          </section>
          <section>
            <h1>Marken</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-3">
                <TextArea
                  name="brands.column1"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Spalte 1"}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <TextArea
                  name="brands.column2"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Spalte 2"}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <TextArea
                  name="brands.column3"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Spalte 3"}
                />
              </div>
              <div className="col-xs-12 col-sm-3">
                <TextArea
                  name="brands.column4"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Spalte 4"}
                />
              </div>
            </div>
          </section>
          <section>
            <h1>Content (Welche Brille passt zu mir)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <TextArea
                  name="content2.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="content2.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <TextArea
                  name="content2.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content2.imageDesktop"
                      title="Bild Desktop"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content2.imageMobile"
                      title="Bild Mobile"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h1>Content (Farbberatung)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <TextArea
                  name="content3.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="content3.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <TextArea
                  name="content3.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content3.imageDesktop"
                      title="Bild Desktop"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content3.imageMobile"
                      title="Bild Mobile"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h1>Content (ZEISS)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <TextArea
                  name="content4.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="content4.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <TextArea
                  name="content4.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content4.imageDesktop"
                      title="Bild Desktop"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="content4.imageMobile"
                      title="Bild Mobile"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <h1>Content (Technik)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <TextArea
                  name="content5.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="content5.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
                <TextArea
                  name="content5.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
              </div>
              <div className="col-xs-12 col-sm-6">
                <TextArea
                  name="content5.list1Header"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Liste 1 Überschrift"}
                  rows={1}
                />
                <TextArea
                  name="content5.list1Text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Liste 1 Punkte"}
                  rows={4}
                />
                <TextArea
                  name="content5.list2Header"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Liste 2 Überschrift"}
                  rows={1}
                />
                <TextArea
                  name="content5.list2Text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Liste 2 Punkte"}
                  rows={4}
                />
              </div>
            </div>
          </section>
          <section>
            <h1>Content (Kontaktbereich)</h1>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <TextArea
                  name="contact.title"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Überschrift"}
                />
                <TextArea
                  name="contact.subtitle"
                  state={this.state.data}
                  onChange={this.updateData}
                  title="Untertitel"
                  rows={4}
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <TextArea
                  name="contact.text"
                  state={this.state.data}
                  onChange={this.updateData}
                  title={"Text"}
                  rows={8}
                />
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="contact.imageDesktop"
                      title="Bild Desktop"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <ImageUpload
                      name="contact.imageMobile"
                      title="Bild Mobile"
                      uploadFile={this.uploadFile}
                      state={this.state.data}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CMS;
